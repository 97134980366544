import * as React from "react";
import { Route, useLocation, Switch, useParams } from "react-router-dom";
// import Main from "./components/MainComponent";
import Login from "./components/loginComponent";
import MCQ from "./components/questions/MCQComponent";
import TrueOrFalse from "./components/questions//TrueOrFalse";
import FillTheBlank from "./components/questions//FillTheBlank";
import VideoComponent from "./components/questions//VideoComponent";
import DashBoard from "./components/DashBoard/DashBoardComponent";
import { ToastProvider } from "react-toast-notifications";
import OrderComponent from "./components/questions/OrderComponent";
import MatchQComponent from "./components/questions/MatchQComponent";
import EssayComponent from "./components/questions/EssayComponent";
import AudioComponent from "./components/questions/AudioComponent";
import LabelQComponent from "./components/questions/LabelQComponent";
import ImageHighlightComponent from "./components/questions/ImageHighlightComponent";
import FileUploadEnhanced from "./components/questions/FileUploadEnhanced";
import assessmentComponent from "./components/AssessmentView/assessmentComponent";
import assessmentViewComponent from "./components/AssessmentView/AssessmentView";
import SideNav from "./components/DashBoard/SideNavComponent";
import StudentAssessment from "./components/AssessmentView/StudentAssessment";
import Cookies from 'js-cookie';
import { BASE_URL, REACT_APP_LOGIN } from './variables/api';
import jwt_decode from "jwt-decode";
import { useEffect, useState } from "react";
import Swal from 'sweetalert2';
import axios from 'axios';
import { useDispatch } from "react-redux";
import { SET_USER_LOGIN } from "./store/ActionTypes";
import VeiwExamAsStudent from "./components/AssessmentView/VeiwExamAsStudent";
import SchoolSelectInfoBar from "./components/DashBoard/SchoolSelectInfoBar";
import useCheckSuperAdmin from "./hooks/useCheckSuperAdmin";
import StudentsExams from "./components/DashBoard/pages/StudentsExams";
import { setTokenExpiration } from "./helpers/auth-refresh";
import Loading from "./components/MyComponents/MyLoading";
import { MathJaxContext } from "better-react-mathjax";
import StudentCompetition from "./components/AssessmentView/StudentCompetition";


axios.defaults.withCredentials = true;

export default function App() {
  const config = {
    loader: { load: ["input/asciimath"] }
};
  const dispatch = useDispatch();
  const location = useLocation();
  // const permissions = useSelector((state: IStateType) => state.permissions);
  const ifSuperAdmin = useCheckSuperAdmin()
  const [loading, setLoading] = useState<boolean>(true);
  const [email, setEmail] = useState<string>('');
  const [userRole, setRole] = useState<{ id: string, rolename: string }>({ id: "", rolename: "" });
  useEffect(() => {
    const token = Cookies.get("quiztoken");
    const tokentest = Cookies.get("tokentest");
    if (tokentest) {
      Cookies.set("tokentest", tokentest, { sameSite: 'none', secure: true });
    }
    const emaildomain = Cookies.get("emaildomain");
    if (emaildomain) {
      Cookies.set("emaildomain", emaildomain, { sameSite: 'none', secure: true });
      axios.defaults.headers.common['emaildomain'] = `${emaildomain}`;
    }
    const schoolLocallyStored = localStorage.getItem("selected_school");
    if (schoolLocallyStored) {
      axios.defaults.headers.common['emaildomain'] = `${JSON.parse(schoolLocallyStored).value}`;
    }
    let decodedToken: any = {};
    localStorage.setItem("test", window.location.href)
    try {
      // student case where no quiztoken but there is link token
      let ruleSetted = false
      if (location.pathname === '/assessment' || location.pathname === '/assessmentViewComponent' || location.pathname === '/competition') {
        if (tokentest) {
          ruleSetted = true;
          setRole({ id: "", rolename: "student" })
          return;
        } 
      }
        if (token) {
          decodedToken = jwt_decode(token || "");
          if (decodedToken && decodedToken.data) {
            setTokenExpiration(decodedToken);
            setRole(decodedToken.data.userRole)
            //dispatch({ type: SET_USER_LOGIN, user: decodedToken.data })
            localStorage.setItem('emaildomain', decodedToken.data.userName.split("@")[1])
          }
          return;
        } else {
          axios.post(`${BASE_URL}/auth/login.php?link=` + window.location.href)
            .then((response: any) => {
              // if(response="internal system error! ")
              // {
              //     Swal.fire("refresh your wen page","success");
              // }
              //logging.info(response.data.user, 'SAML');
              if (response.data.data.token) {
                const token = response.data.data.token;
                Cookies.set("quiztoken", token, { sameSite: 'none', secure: true });
                decodedToken = jwt_decode(token);
                setTokenExpiration(decodedToken);
                localStorage.setItem('emaildomain', decodedToken.data.userName.split("@")[1])
                dispatch({ type: SET_USER_LOGIN, user: decodedToken.data })

                setRole(decodedToken.data.userRole)
                setEmail(response.data.userName);
                setLoading(false);
              }
              else {
              }
            })
            .catch(error => {
             
            })

        }
    } catch (e) {
      let ee = e as any;
     
      Swal.fire(ee?.response?.data.msg || "Failed to login", "", "error");
    }
  }, []);
 
  if (userRole.rolename === "student") {
    return (
      <MathJaxContext config={config}>
        <React.Fragment>
          <ToastProvider>
            <Switch>
              <Route path="/assessmentcomponent" component={assessmentComponent} />
              <Route path="/assessmentViewComponent" component={assessmentViewComponent} />
              <Route path="/assessment" component={StudentAssessment} />
              <Route path="/competition" component={StudentCompetition} />
              <Route path="/veiwexamasstudent" component={VeiwExamAsStudent} />
              <Route path="/login" component={Login} />
            </Switch>
          </ToastProvider>
        </React.Fragment>
      </MathJaxContext>
    );
  } else if (userRole.rolename === "Admin" || userRole.rolename === "Teacher") {
    return (
      <React.Fragment>
        <ToastProvider>
          {ifSuperAdmin && <SchoolSelectInfoBar />}
          <MathJaxContext config={config}>
            <Switch>
              <Route path="/mcq" component={MCQ} />
              <Route path="/Order" component={OrderComponent} />
              <Route path="/Match" component={MatchQComponent} />
              <Route path="/Label" component={LabelQComponent} />
              <Route path="/truefalse" component={TrueOrFalse} />
              <Route path="/essay" component={EssayComponent} />
              <Route path="/videoqst" component={VideoComponent} />
              <Route path="/audioqst" component={AudioComponent} />
              <Route path="/fileuploadenhanced" component={FileUploadEnhanced} />
              <Route path="/filltheblank" component={FillTheBlank} />
              <Route path="/imagehighlight" component={ImageHighlightComponent} />
              <Route path="/assessmentcomponent" component={assessmentComponent} />
              <Route path="/assessmentViewComponent" component={assessmentViewComponent} />
              <Route path="/assessment" component={StudentAssessment} />
              <Route path="/competition" component={StudentCompetition} />
              <Route path="/sidnav" component={SideNav} />
              <Route path="/veiwexamasstudent" component={VeiwExamAsStudent} />
              <Route path="/login" component={Login} />
              <Route path="/dashboard" component={DashBoard} />
              <Route exact path="/" component={DashBoard} />
              {/* <Route path="/dashboard/StudentsExams" component={StudentsExams} /> */}
              {/* <Redirect to="/dashboard/assessments" />  */}
            </Switch>
          </MathJaxContext>
        </ToastProvider>
      </React.Fragment>
    );
  } else {
    return <h1> Loading...</h1>
  }

}
function dispatch(arg0: any) {
  throw new Error("Function not implemented.");
}

