let domain = ""
if(window.location.origin.includes("lwe") ){
    domain = "lwe"

}else {
    domain = "localhost"
}

export const BASE_URL = domain == "localhost" ? "http://localhost/LWE_Exams/backend/" : "https://exam.lwe.ai/backend/"
export const REACT_APP_LOGIN = domain == "localhost" ? "http://localhost/LWE_Exams/backend/login.php" : ""
export const REACT_APP_LOGOUT = domain == "localhost" ? "http://localhost/LWE_Exams/backend/logout.php" :  ""


//NODE_ENV === 'development' ? "http://localhost/Students_Affairs/backend/" : 'https://sis.ivyis.org/backend/';